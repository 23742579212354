<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 py-4 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  :src="require('@/assets/imgs/pages/security3.png')"
                  alt="Icona sicurezza"
                  class="service_image_position"
                  contain
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">La tua impresa è sicura?</h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/security3.png')"
                    alt="Icona sicurezza"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">
                  Proteggiti e non renderti vulnerabile!
                </h2>

                <p>
                  La sicurezza informatica, o cyber security, è un insieme di
                  pratiche, attività e abitudini atte a proteggere nel migliore
                  dei modi informazioni e dispositivi e si sviluppa attorno a
                  tre elementi base:
                  <br />
                  la confidenzialità, l'integrità e l'accessibilità dei dati.
                </p>

                <p>
                  Un'azienda sicura deve poter quindi proteggere i propri dati
                  da occhi indiscreti, assicurarsi che nessuno li abbia
                  modificati senza autorizzazione e potervi accedere quando
                  necessario.
                </p>

                <p>
                  La sicurezza informatica, applicata alle infrastrutture
                  aziendali, è composta da un insieme di elementi come ad
                  esempio:<br />
                  - formazione utente alle pratiche corrette
                  <br />
                  - separazione per ruoli nell'accesso ai dati
                  <br />
                  - verifica dello stato dei backup
                  <br />
                  - creazione di piani di disaster recovery,
                  <br />
                  - mantenimento di firewall perimetrali
                  <br />
                  - aggiornamento dei sitemi con l'installazione delle ultime
                  patch di sicurezza.
                </p>

                <p>
                  Se noti qualcosa di sospetto o hai il timore che determinate
                  attività possano mettere a rischio la sicurezza della tua
                  azienda, contattaci e assieme cercheremo la soluzione più
                  adatta alla tua realtà.
                </p>

                <!-- <v-btn
                  :to="{ name: 'Contacts', params: { topic: 'security' } }"
                  outlined
                  block
                >
                  Contattaci
                </v-btn> -->
              </v-col>

              <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="security"
                  title="Vuoi rendere più sicura la tua azienda?"
                />
              </v-col>
              <!-- 
              <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Protezione & Sicurezza",
    meta: [
      {
        name: "description",
        content:
          "La sicurezza dei tuoi dati è fondamentale. Previeni i furti e la perdita di informazioni con un adeguata formazione e politiche di sicurezza.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>